import React, { useContext, useState } from "react";
import { css } from "@emotion/react";

import { useDisclosure } from "@chakra-ui/react";
import {
  AspectRatio,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
} from "@chakra-ui/react";
import get from "lodash.get";

const VideoModalContext = React.createContext({});

const btnStyle = css`
  top: calc(100% + 30px);
  right: 50%;
  transform: translateX(50%);
  border-radius: 9999px;
  background-color: white;
  width: 60px;
  height: 60px;

  &:hover,
  [data-hover] {
    background-color: black;
    color: white;
  }

  .chakra-icon {
    width: 24px;
    height: 24px;
  }
`;

export const parseVideoId = (url) => {
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  if (typeof url !== "string") {
    return;
  }

  const matches = url.match(
    /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/
  );

  return get(matches, 7);
};

export const isVideoUrl = (url) => !!parseVideoId(url);

const isYoutubeUrl = (url) =>
  typeof url === "string" &&
  /(http:|https:|)\/\/(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/.test(
    url
  );

const isVimeoUrl = (url) =>
  typeof url === "string" &&
  /(http:|https:|)\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/.test(
    url
  );

export const videoEmbedUrl = (url) => {
  if (isYoutubeUrl(url)) {
    return `https://www.youtube.com/embed/${parseVideoId(url)}?autoplay=1`;
  }

  if (isVimeoUrl(url)) {
    return `https://player.vimeo.com/video/${parseVideoId(url)}?autoplay=true`;
  }

  return url;
};

export const VideoModalProvider = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [videoUrl, setVideoUrl] = useState();

  const openModal = (url) => {
    if (!url) {
      return;
    }

    setVideoUrl(url);
    onOpen();
  };

  const closeModal = () => {
    setVideoUrl(undefined);
    onClose();
  };

  return (
    <VideoModalContext.Provider
      value={{ videoUrl, setVideoUrl, isOpen, openModal, closeModal }}
    >
      {children}

      <Modal isCentered isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay>
          <ModalContent>
            <ModalCloseButton css={btnStyle} />
            <AspectRatio maxW="1080px" ratio={16 / 9}>
              <iframe
                src={videoEmbedUrl(videoUrl)}
                frameBorder="0"
                allow={
                  "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                }
                title="Watch Video"
                tabIndex="-1"
                allowFullScreen
              />
            </AspectRatio>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </VideoModalContext.Provider>
  );
};

const useVideoModal = () => {
  const context = useContext(VideoModalContext);
  return context;
};

export default useVideoModal;
