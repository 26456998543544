import React, { useContext } from "react";
import _get from "lodash.get";
import _isEqual from "lodash.isequal";

const PreviewContext = React.createContext({});

const comparePreview = (prev, next) =>
  _isEqual(_get(prev, "children.props"), _get(next, "children.props"));

export const PreviewProvider = React.memo(({ children, getAsset }) => {
  const previewComponents = {
    img: ({ node, src, ...props }) => (
      <img src={getAsset(src).toString()} alt="" {...props} />
    ),
  };

  return (
    <PreviewContext.Provider
      value={{ isPreview: true, previewComponents, getAsset }}
    >
      {children}
    </PreviewContext.Provider>
  );
}, comparePreview);

const usePreview = () => {
  const context = useContext(PreviewContext);

  if (!context) {
    return { isPreview: false };
  }

  return context;
};

export default usePreview;
