import { css } from "@emotion/react";
import * as themeColors from "./colors";

// Inter font weights.
export const fontWeights = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const percent = (n) => `${Math.floor(n * 100)}%`;

export const rem = (px) => `${px / 16}rem`;

export const fontSize = (px, lineHeight) => css`
  font-size: ${rem(px)};
  line-height: ${(lineHeight && lineHeight / px) || "1.2"};
`;

export const maxWidthpx = 1200;
export const maxWidth = rem(1200);

export const debugCenterLine = css`
  &::after {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 50%;
    display: block;
    width: 1px;
    background: red;
    z-index: 20000;
  }
`;

export const colors = themeColors;
