import React from "react";
import SVGIcon from "./svg-icon";

const PersonIcon = ({ color, ...props }) => (
  <SVGIcon {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M6.1 7.6c4.2 0 6.1 3 6.1 4.2 0 .8-.4 1.2-1.3 1.2H1c-.7 0-1-.4-1-1.2 0-1.5 2.8-4.2 6.1-4.2zm0-7.6c1.6 0 3 1.4 3 3.2 0 1.7-1.4 3.2-3 3.2s-3-1.5-3-3.2c0-1.8 1.4-3.2 3-3.2z"
    />
  </SVGIcon>
);

PersonIcon.defaultProps = {
  viewBox: "0 0 12 12",
  width: 12,
  height: 12,
  color: "currentColor",
};

export default PersonIcon;
