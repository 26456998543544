import React from "react";
import _get from "lodash.get";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import get from "lodash.get";

import usePreview from "app/cms/usePreview";

const PreviewImage = ({ image, ...props }) => {
  const { getAsset } = usePreview();
  const src = typeof getAsset === "function" && getAsset(image);

  return !src ? null : (
    <div {...props}>
      <img
        src={src}
        alt=""
        style={{ objectFit: "cover", width: "100%", height: "100%" }}
      />
    </div>
  );
};

const Image = ({ alt, image, imageUrl, images, src, ...props }) => {
  const { isPreview } = usePreview();
  const gatsbyImage = getImage(image);

  if (isPreview) {
    return <PreviewImage image={image} {...props} />;
  }

  const markdownImage =
    Array.isArray(images) &&
    images.find(({ relativePath }) =>
        relativePath.includes(_get(props, "node.properties.src"))
    );
  if (markdownImage) {
    const imageData = getImage(markdownImage);
    if (imageData) {
      return <GatsbyImage image={imageData} alt={alt} {...props} />;
    }
  }

  if (!!gatsbyImage) {
    return <GatsbyImage image={gatsbyImage} alt={alt} {...props} />;
  }

  const imgSrc = [get(image, "publicURL"), image, imageUrl].find(
    (value) => typeof value === "string" && value.length
  );

  return imgSrc ? <img src={imgSrc} alt={alt} {...props} /> : null;
};

Image.defaultProps = {
  alt: "",
};

export const imageField = {
  label: "Image",
  name: "image",
  widget: "image",
};

export default Image;
