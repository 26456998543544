import React from "react";
import _get from "lodash.get";
import { graphql } from "gatsby";

import Link from "./link";

import * as S from "./btn.styles";

const BaseBtn = ({ variant, ...props }) => (
  <Link Component={S.Btn} css={_get(S, variant)} {...props} />
);

const Btn = ({ center, ...props }) =>
  (center && (
    <S.CenterWrap>
      <BaseBtn {...props} />
    </S.CenterWrap>
  )) || <BaseBtn {...props} />;

export const btnFields = {
  label: "Link",
  name: "link",
  widget: "object",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "string",
      required: false,
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
      required: false,
    },
    {
      label: "New Window",
      name: "newWindow",
      widget: "boolean",
      required: false,
    },
  ],
};

export const ctaFragment = graphql`
  fragment CTA on ContentJsonCTA {
    label
    url
    newWindow
  }
`;

export default Btn;
