import React from "react";
import clsx from "clsx";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkDirective from "remark-directive";
import remarkDirectiveRehype from "remark-directive-rehype";
// import _get from "lodash.get";

import Image from "./image";
import Btn from "./btn";
import Link from "./link";
import * as S from "./markdown-block.styles";

import usePreview from "../cms/usePreview";

export const ALink = (props) => <Link css={S.link} {...props} />;

const headingSizes = {
  hero: "text-5xl",
  h1: "text-4xl",
  h2: "text-3xl",
  h3: "text-2xl",
  h4: "text-xl",
  h5: "text-lg",
  h6: "text-base",
};

export const Heading = ({ children, className, node: { tagName: Tag } }) => {
  return (
    <Tag
      className={clsx([
        "mb-5 font-bold tracking-tighter",
        headingSizes[Tag],
        className,
      ])}
    >
      {children}
    </Tag>
  );
};

const HeadingDynamic = ({ tag: Tag, variant, align, maxWidth, children }) => {
  return (
    <Tag
      className={clsx(
        ["mb-5 font-bold tracking-tighter", headingSizes[variant]],
        {
          "text-center": align === "center",
          "text-right": align === "right",
          "mx-auto": align === "center" && !!maxWidth,
        }
      )}
      style={{ maxWidth: !!maxWidth ? `${maxWidth}px` : undefined }}
    >
      {children}
    </Tag>
  );
};

export const UList = ({ children, className }) => (
  <ul
    className={clsx(["list-disc-custom mb-8 flex flex-col gap-4", className])}
  >
    {children}
  </ul>
);

export const OList = ({ children, className }) => (
  <ol
    className={clsx([
      "mb-8 ml-5 flex list-decimal flex-col gap-4 [&_li]:pl-2",
      className,
    ])}
  >
    {children}
  </ol>
);

const P = ({ children, className }) => (
  <p className={className} css={S.mb8}>
    {children}
  </p>
);

const QuoteMark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 47 39"
    {...props}
  >
    <path
      fill="#282828"
      d="M20.9 5a79 79 0 00-5.4 3.3c-1.3.9-2.7 2.1-4.1 3.6a16.6 16.6 0 00-3 4.4c-.7 1.6-1.2 3.6-1.5 6h3.4c2.9 0 5.1.7 6.8 2.2 1.7 1.4 2.5 3.5 2.5 6.2 0 2-.7 3.9-2.2 5.6a7.6 7.6 0 01-6 2.5c-4.1 0-7-1.3-8.7-4A19 19 0 010 24.5c0-3 .6-5.8 2-8.3 1.3-2.5 2.8-4.8 4.7-6.7 2-2 4-3.8 6.1-5.2L18.4.8 20.9 5zm26 0a79 79 0 00-5.3 3.3 23.3 23.3 0 00-7.3 8.1 21 21 0 00-1.4 6h3.4c2.9 0 5.2.6 6.8 2 1.7 1.5 2.5 3.6 2.5 6.3 0 2-.7 3.9-2.2 5.6a7.6 7.6 0 01-6 2.5c-4 0-7-1.3-8.7-4A19 19 0 0126 24.5c0-3 .6-5.8 2-8.3 1.3-2.5 2.9-4.8 4.7-6.7 2-2 4-3.8 6.2-5.2L44.4.8 46.9 5z"
    ></path>
  </svg>
);

QuoteMark.defaultProps = {
  width: 47,
  height: 39,
};

const Strong = ({ children }) => <strong css={[S.semiBold]}>{children}</strong>;

const Bold = ({ children }) => <b css={[S.semiBold]}>{children}</b>;

const Blockquote = ({ children }) => (
  <blockquote css={[S.blockQuote, S.bold, S.txtCenter, S.maxW600px, S.mxauto]}>
    <QuoteMark css={[S.mxauto, S.mb4]} />
    {children}
  </blockquote>
);

const Code = ({ children, className }) => {
  if (className === "language-jsonCTA") {
    try {
      const props = JSON.parse(children[0]);
      return <Btn css={[S.mb8]} {...props} />;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  return <code className={className}>{children}</code>;
};

const Pre = ({ children }) => <>{children}</>;

const CTAButton = ({ url, label, newWindow }) => {
  return (
    <div>
      <Btn url={url} label={label} newWindow={newWindow === "true"} />
    </div>
  );
};

const MarkdownBlock = ({ content, images, components }) => {
  const { previewComponents } = usePreview();

  return (
    <ReactMarkdown
      remarkPlugins={[remarkDirective, remarkDirectiveRehype, remarkBreaks]}
      children={content}
      components={{
        "cta-button": CTAButton,
        heading: HeadingDynamic,
        h1: Heading,
        h2: Heading,
        h3: Heading,
        h4: Heading,
        h5: Heading,
        h6: Heading,
        ul: UList,
        ol: OList,
        strong: Strong,
        b: Bold,
        p: P,
        img: (props) => <Image images={images} {...props} />,
        blockquote: Blockquote,
        a: ALink,
        code: Code,
        pre: Pre,
        ...previewComponents,
        ...components,
      }}
    />
  );
};

export const MarkdownBlockContainer = ({ textAlign, ...props }) => (
  <div
    className={clsx("container py-[3.75rem]", {
      "flex flex-col items-center text-center": textAlign === "center",
      "flex flex-col items-end text-right": textAlign === "right",
    })}
  >
    <MarkdownBlock {...props} />
  </div>
);

export const markdownBlockField = {
  label: "MarkdownBlock",
  name: "markdownBlock",
  widget: "object",
  fields: [
    {
      label: "Content",
      name: "content",
      widget: "markdown",
    },
  ],
};

export default MarkdownBlock;
