import React from "react";
import _get from "lodash.get";
import { useMeasure } from "react-use";
import { useWindowScroll } from "react-use";

import AlertMessage from "./alert-message";
import Link from "./link";
import Logo from "./logo";
import Navigation from "./navigation";
import NavDrawer, { useNavDrawer } from "./nav-drawer";

import SiteData from "../content/site-globals.json";

import * as S from "./header.styles";

const HomeLink = ({ isFull, ...props }) => (
  <Link to="/#top" css={[S.colorInherit, S.logo]} {...props}>
    <Logo
      css={[S.colorInherit, S.logoImg]}
      isFull={isFull}
      color={"currentColor"}
    />
  </Link>
);

const TransitionBox = ({ children, ...props }) => {
  const { y } = useWindowScroll();
  return React.cloneElement(children, { ...props, isTransparent: y < 75 });
};

const MenuToggle = ({ className }) => {
  const { isOpen, onOpen, onClose } = useNavDrawer();
  return (
    <button
      onClick={isOpen ? onClose : onOpen}
      css={[S.baseBtn, S.toggleBtn]}
      className={className}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 28 20"
        width={28}
        height={20}
      >
        <path
          stroke={"currentColor"}
          strokeWidth="3"
          d="M.5 1.9h26.6M.5 10.3h26.6M.5 18.3h26.6"
        />
      </svg>
    </button>
  );
};

const Navbar = ({ isTransparent, isMobile, isHome, ...props }) => (
  <header
    css={[
      S.navbar,
      S.navGrid,
      isHome && S.negativeSpace,
      isTransparent ? S.transparent : S.solid,
    ]}
  >
    {isMobile && <MenuToggle {...props} />}
    <HomeLink isFull={!isMobile} css={isTransparent ? S.hidden : S.shown} />
    {!isMobile && <Navigation css={S.navigation} />}
  </header>
);

const ResponsiveHeader = ({ isHome, Page, ...props }) => {
  const [ref, { width }] = useMeasure();
  const isMobile = width <= 800;
  const HeaderBox = isHome ? TransitionBox : React.Fragment;
  const Wrapper = isMobile ? NavDrawer : React.Fragment;

  return (
    <>
      <div ref={ref} />
      <Wrapper>
        {_get(SiteData, "alertMessage.enabled") && (
          <AlertMessage content={_get(SiteData, "alertMessage.content")} />
        )}
        <HeaderBox>
          <Navbar isMobile={isMobile} isHome={isHome} {...props} />
        </HeaderBox>

        {Page && React.cloneElement(Page)}
      </Wrapper>
    </>
  );
};

export default ResponsiveHeader;
