import React, { memo } from "react";

export const SVGIcon = ({ children, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMinYMin meet"
    {...props}
  >
    {children}
  </svg>
);

SVGIcon.defaultProps = {
  width: "60",
  height: "60",
  viewBox: "0 0 60 60",
};

export default memo(SVGIcon);
