import React from "react";
import _get from "lodash.get";

import Icon from "./icons/person-icon";
import * as S from "./btn.styles";

import SiteData from "../content/site-globals.json";

const BtnSignIn = ({ iconProps, small, ...props }) => {
  if (!_get(SiteData, "signInButton.enabled")) {
    return null;
  }

  return (
    <S.BtnOutlined
      href={_get(SiteData, "signInButton.url")}
      onMouseDown={(e) => {
        e.preventDefault();
        e.target.blur();
      }}
      target={"_blank"}
      rel={"nofollow noopener"}
      {...props}
    >
      {!small && (
        <S.SignInText>{_get(SiteData, "signInButton.label")}</S.SignInText>
      )}
      <Icon {...iconProps} />
    </S.BtnOutlined>
  );
};

export default BtnSignIn;

export const signInFields = {
  label: "SignIn Button",
  name: "signInButton",
  widget: "object",
  fields: [
    {
      label: "Enabled",
      name: "enabled",
      widget: "boolean",
      required: false,
    },
    {
      label: "Label",
      name: "label",
      widget: "string",
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
    },
  ],
};
