import { css } from "@emotion/react";

import { rem, fontSize } from "../styles/utils";
import { grayDark } from "../styles/colors";
import { mobileGutter } from "../styles/shared";

export {
  container,
  bold,
  semiBold,
  mb8,
  mb4,
  py15,
  mxauto,
  textLg,
  textXl,
  text2xl,
  text3xl,
  text4xl,
  textBase,
  txtCenter,
  txtRight,
} from "../styles/shared";

export const wrap = css`
  ${mobileGutter};
  max-width: ${rem(860)};
  margin: 0 auto;
  padding-top: ${rem(120)};
  padding-bottom: ${rem(120)};
`;

export const largeText = css`
  ${fontSize(18, 26)};
  color: ${grayDark};
`;

export const maxW600px = css`
  max-width: 600px;
`;

export const maxW800px = css`
  max-width: 800px;

  .text-center & {
    margin-left: auto;
    margin-right: auto;
  }
`;

export const blockQuote = css`
  font-size: 26px;
`;

export const list = css`
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
  list-style: none;
`;

export const Olist = css`
  list-style: decimal;
  margin-left: 1.125rem;

  li {
    padding-left: ${rem(8)};
  }
`;

export const listitem = css`
  position: relative;
  margin-bottom: 0;
  padding-left: ${rem(20)};

  ul > &::before {
    display: block;
    height: ${rem(8)};
    width: ${rem(8)};
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0.5rem, 0);
    background-color: #757575;
    content: "";
  }

  li + & {
    margin-top: ${rem(20)};
  }
`;

export const link = css`
  display: inline-block;
  color: var(--link-color);
  text-decoration: none;
  border-bottom: ${rem(1)} solid var(--link-border);
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: var(--link-color);
    border-color: transparent;
  }
`;
