import React from "react";
import clsx from "clsx";
import _get from "lodash.get";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  useDisclosure,
  Collapse,
} from "@chakra-ui/react";

import Link from "./link";
import BtnSignIn from "./btn-sign-in";

import SiteData from "../content/site-globals.json";

import useDemoLink from "../hooks/use-demo-link";

import * as S from "./navigation.styles";

const DemoLink = ({ newWindow, ...props }) => {
  const demoLink = useDemoLink();

  return (
    <Link
      label="Request Demo"
      href={demoLink}
      target={!newWindow ? undefined : "_blank"}
      data-track-action="Click"
      data-track-category="homepage"
      data-track-label="request-demo-stickynav"
      {...props}
    />
  );
};

const SignInLink = () => <BtnSignIn text />;

const linkTypes = {
  "@demoLink": DemoLink,
  "@signInLink": SignInLink,
};

const DropdownNav = ({ subpages, ...props }) => (
  <Popover trigger={"hover"}>
    {({ onClose, isOpen }) => (
      <>
        <PopoverTrigger>
          <button
            css={[S.bold, S.navLink]}
            className={clsx(
              "inline-flex items-center justify-center gap-1",
              isOpen && "active"
            )}
          >
            {_get(props, "label")}
            <svg
              className="h-1.5 w-1.5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 6"
              width="6"
              height="6"
            >
              <path fill="currentColor" d="M2.86 5.22.26.72h5.2l-2.6 4.5Z" />
            </svg>
          </button>
        </PopoverTrigger>
        <PopoverContent css={S.subPanel}>
          <PopoverArrow />
          {subpages.map((link, idx) => (
            <Link
              key={`link-${idx}`}
              onClick={onClose}
              css={[
                S.grayDark,
                S.bold,
                S.navLinkText,
                idx < subpages.length - 1 && S.mb4,
              ]}
              {...link}
            />
          ))}
        </PopoverContent>
      </>
    )}
  </Popover>
);

const ToggleArrow = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 13 16"
    width={13}
    height={16}
    className={className}
  >
    <path fill="#221F20" d="M12.35 7.93L.06 15.03V.82l12.3 7.1z" />
  </svg>
);

const SubMenu = ({ subpages, ...props }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <button css={[S.bold, S.navLink, S.relative]} onClick={onToggle}>
        <ToggleArrow css={[S.toggleArrow, isOpen && S.arrowRotate]} />
        {_get(props, "label")}
      </button>
      <Collapse in={isOpen} animateOpacity>
        <ul css={[S.baseList, S.bgWhite]}>
          {subpages.map((link) => (
            <li>
              <Link css={[S.navLink, S.borderLiTop]} {...link} />
            </li>
          ))}
        </ul>
      </Collapse>
    </>
  );
};

const navLinkStyles = {
  link: S.navLink,
  button: S.navButton,
};

const NavLink = ({ url, subpages, isMobile, linkStyle, ...props }) => {
  const Component = _get(linkTypes, url) || Link;
  const isParent = Array.isArray(subpages) && subpages.length;

  if (isParent) {
    return isMobile ? (
      <SubMenu subpages={subpages} {...props} />
    ) : (
      <DropdownNav subpages={subpages} {...props} />
    );
  }

  return (
    <Component
      {...props}
      url={!isParent ? url : undefined}
      partiallyActive={
        (Component === Link && url.startsWith("/blog")) || undefined
      }
      activeClassName={"active"}
      css={[S.bold, _get(navLinkStyles, linkStyle, "link")]}
    />
  );
};

const Navigation = ({ className, isMobile }) => (
  <nav className={className}>
    <ul css={[S.baseList, S.navList]}>
      {_get(SiteData, "navigation", []).map((link, idx) => (
        <li key={idx} css={S.navLi}>
          <NavLink {...link} isMobile={isMobile} />
        </li>
      ))}
    </ul>
  </nav>
);

export default Navigation;

export const navigationFields = {
  label: "Header Nav",
  name: "navigation",
  widget: "list",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "string",
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
    },
    {
      label: "Link Style",
      name: "linkStyle",
      widget: "select",
      options: ["link", "button"],
      default: "link",
    },
    {
      label: "New Window",
      name: "newWindow",
      widget: "boolean",
      required: false,
    },
    {
      label: "Sub Pages",
      name: "subpages",
      widget: "list",
      fields: [
        {
          label: "Label",
          name: "label",
          widget: "string",
        },
        {
          label: "Url",
          name: "url",
          widget: "string",
        },
        {
          label: "New Window",
          name: "newWindow",
          widget: "boolean",
          required: false,
        },
      ],
      required: false,
    },
  ],
};
