import React from "react";
import _get from "lodash.get";
import { Link as GatsbyLink } from "gatsby";
import useVideoModal, { parseVideoId } from "../hooks/use-video-modal";
import useQueryParams from "app/hooks/use-query-params";

import * as S from "./link.styles";
import get from "lodash.get";

export const blur = (e) => {
  e.preventDefault();
  e.target.blur();
};

export const handleOnMouseDown = ({ onMouseDown }) => ({
  onMouseDown: (e) => {
    blur(e);
    typeof onMouseDown === "function" && onMouseDown(e);
  },
});

export const handleTargetBlank = ({ rel, new_window, newWindow, target }) => {
  const linkTarget = new_window || newWindow ? "_blank" : target;
  return {
    rel: [rel, linkTarget === "_blank" ? "noopener noreferrer" : ""]
      .filter((t) => t)
      .join(" "),
    target: linkTarget,
  };
};

export const relativize = (url) => {
  const origin =
    typeof window !== "undefined" && _get(window, "location.origin");
  return origin && typeof url === "string" ? url.replace(origin, "") : url;
};

const targetsNewWindow = (props) =>
  get(props, "new_window") ||
  get(props, "newWindow") ||
  get(props, "target") === "_blank";

export const handleLinkUrl = (props) => {
  const link =
    _get(props, "to") ||
    _get(props, "uri") ||
    _get(props, "file.publicURL") ||
    relativize(_get(props, "href") || _get(props, "url"));

  if (!link) {
    return {
      as: "button",
      "data-nolink": true,
    };
  }

  if (/^https?:\/\/|mailto:|tel:/i.test(link) || targetsNewWindow(props)) {
    return {
      href: link,
      to: undefined,
    };
  }

  if (/^#/i.test(link)) {
    return {
      href: link,
    };
  }

  const { search, pathname, hash } = new URL(
    `https://tedatwork.ted.com/${link.replace(/^\//, "")}`
  );

  const queryParams = search.includes("?")
    ? search
    : _get(props, "queryParams", "");

  return {
    to: `${pathname}${queryParams}${hash}`.replace(/^\/\//, "/"),
    as: GatsbyLink,
  };
};

export const VideoBtn = ({ videoUrl, Component, url, href, ...rest }) => {
  const { openModal } = useVideoModal();
  return <Component {...rest} onClick={() => openModal(videoUrl)} />;
};

export const getLinkProps = ({ to, href, uri, ...props }) => ({
  ...props,
  ...handleLinkUrl({ to, href, uri, ...props }),
  ...handleOnMouseDown(props),
  ...handleTargetBlank(props),
});

const sanitizeLinkProps = ({
  css,
  localFile,
  new_window,
  newWindow,
  queryParams,
  url,
  ...props
}) => props;

const Link = ({
  children,
  label,
  Component,
  optional,
  fallbackAs,
  ...props
}) => {
  const params = useQueryParams();
  const { css, ...linkProps } = getLinkProps({ ...props, queryParams: params });
  const safeProps = sanitizeLinkProps(linkProps);

  const videoUrl = !!parseVideoId(_get(linkProps, "href"))
    ? _get(linkProps, "href")
    : false;

  const BaseLink = ({ variant, ...rest }) => (
    <S.Link css={[_get(S, variant), css]} {...rest} />
  );

  if (videoUrl) {
    return (
      <VideoBtn
        Component={Component || BaseLink}
        videoUrl={videoUrl}
        {...safeProps}
      >
        {children || label}
      </VideoBtn>
    );
  }

  const OptionalLink = ({ variant, as, ...rest }) => {
    if (!!optional && as === "button") {
      const fallbackEl = fallbackAs || "div";
      const { href, to, ...nonLinkProps } = rest;
      return React.createElement(fallbackEl, nonLinkProps);
    }

    return <BaseLink as={as} {...rest} />;
  };

  const Base = Component || OptionalLink;

  return (
    <Base download={_get(linkProps, "file.publicURL")} {...safeProps}>
      {children || label}
    </Base>
  );
};

Link.defaultProps = {
  variant: "text",
};

export default Link;

export const linkFields = {
  label: "Link",
  name: "link",
  widget: "object",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "string",
    },
    {
      label: "Url",
      name: "url",
      widget: "string",
    },
    {
      label: "New Window",
      name: "newWindow",
      widget: "boolean",
      required: false,
    },
  ],
};
