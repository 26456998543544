import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem, fontWeights, colors } from "../styles/utils";

// import { fadeIn } from "../styles/animations";
// import { desktop } from "../styles/breakpoints";

export const invert = ({ invert }) => {
  return (
    invert &&
    css`
      --button-bg: var(--white);
      --button-text: var(--red);
      --button-hover-bg: var(--white);
      --button-hover-text: var(--red);
    `
  );
};

export const dark = ({ dark }) => {
  return (
    dark &&
    css`
      --button-bg: var(--black);
      --button-text: var(--white);
      --button-hover-bg: var(--white);
      --button-hover-text: var(--black);
    `
  );
};

export const btn = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: var(--button-pad-y) var(--button-pad-x);
  font-size: var(--button-font-size);
  line-height: var(--button-leading);
  font-weight: ${fontWeights.bold};
  background-color: var(--button-bg);
  border: var(--button-border-w) solid var(--button-border-color);
  color: var(--button-text);
  min-width: var(--button-min-w);
  border-radius: var(--button-border-radius);
  text-decoration: none;
  cursor: pointer;

  &:focus,
  &:active,
  &:hover {
    background-color: var(--button-hover-bg);
    color: var(--button-hover-text);
  }

  &[disabled],
  &:disabled {
    background-color: var(--button-disabled-bg);
    color: var(--button-disabled-text);
    pointer-events: none;
  }
`;

export const CenterWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Btn = styled.a`
  ${btn};
  ${invert};
  ${dark};
`;

export const BtnOutlined = styled.a`
  --button-border-color: rgba(255, 255, 255, 0.6);
  --button-border-w: 1px;
  --button-min-w: ${rem(80)};
  --button-pad-y: ${rem(14)};
  --button-pad-x: ${rem(14)};
  --button-font-size: ${rem(14)};
  --button-leading: ${20 / 14};

  margin-left: ${rem(15)};

  &:focus,
  &:active,
  &:hover {
    text-decoration: underline;
  }

  ${({ dark }) =>
    dark &&
    css`
      --button-border-color: ${colors.grayBGLight};
      --button-text: var(--gray-dark);
    `}
`;

export const SignInText = styled.span`
  margin: 0 ${rem(8)};
`;

export const TextLink = styled.a`
  display: inline-block;
  color: var(--link);
  text-decoration: none;
  border-bottom: ${rem(1)} solid var(--link-border);
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    color: var(--link);
    border-color: var(--link-border-hover);
  }
`;
