import React from "react";
import { Helmet } from "react-helmet";

import { ChakraProvider } from "@chakra-ui/react";
import Header from "./header";
import Footer from "./footer";
import { VideoModalProvider } from "../hooks/use-video-modal";

import "app/styles/global.css";

const Page = ({ children }) => (
  <div className="overflow-x-hidden">
    <main>{children}</main>
    <Footer />
  </div>
);

const Layout = ({ children, className, isHome }) => (
  <>
    <Helmet>
      <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <ChakraProvider>
      <VideoModalProvider>
        <div className={className}>
          <Header isHome={isHome} Page={<Page children={children} />} />
        </div>
      </VideoModalProvider>
    </ChakraProvider>
  </>
);
export default Layout;
