export const white = "#FFFFFF";
export const black = "#000000";

export const red = "#eb0028";

export const grayDark = "#282828";
export const grayLight = "#808080";
export const grayBGLight = "#D9D9D9";

export const offWhite = "#F8FAFC";

export const border1pxGray = "#eaeaea";

export const textPrimary = grayDark;
export const textSecondary = grayLight;

export const brand = red;
