import { css, keyframes } from "@emotion/react";

const baseAnimate = css`
  animation-fill-mode: both;
  animation-duration: 0.5s;
`;

const fadeInAnim = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const slideInDownAnim = keyframes`
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
`;

export const fadeIn = css`
  ${baseAnimate};
  animation-name: ${fadeInAnim};
`;

export const slideInDown = css`
  ${baseAnimate};
  animation-name: ${slideInDownAnim};
  animation-delay: 0.1s;
`;
