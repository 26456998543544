import React, { useContext } from "react";
import { css } from "@emotion/react";
import { motion } from "framer-motion";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  useDisclosure,
} from "@chakra-ui/react";

import Navigation from "./navigation";

import * as S from "../styles/shared";

export const NavDrawerContext = React.createContext({});

export const useNavDrawer = () => useContext(NavDrawerContext);

const transition = {
  exit: {
    x: 0,
    transition: {
      duration: 0.15,
      ease: "easeInOut",
    },
  },
  enter: {
    x: "calc(100vw - 80px)",
    transition: {
      type: "spring",
      damping: 25,
      stiffness: 180,
    },
  },
};

const navDrawerStyle = css`
  overflow-x: hidden;
  overflow-y: auto;
`;

const MotionBox = motion(Box);

const NavDrawer = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <NavDrawerContext.Provider value={{ isOpen, onOpen, onClose }}>
      <MotionBox
        variants={transition}
        animate={isOpen ? "enter" : "exit"}
        initial="exit"
        exit="exit"
      >
        {children}
      </MotionBox>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerContent
          style={{
            width: "calc(100vw - 80px)",
          }}
        >
          <DrawerBody css={[S.p0, S.bgOffWhite, S.relative, navDrawerStyle]}>
            <div css={S.dropShadow} />
            <Navigation isMobile />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </NavDrawerContext.Provider>
  );
};

export default NavDrawer;
