import { css } from "@emotion/react";

export const red = css`
  color: var(--red);
`;

export const grayDark = css`
  color: var(--gray-dark);
`;

export const uppercase = css`
  text-transform: uppercase;
`;

export const normal = css`
  font-weight: 400;
`;

export const bold = css`
  font-weight: 700;
`;

export const semiBold = css`
  font-weight: 600;
`;

export const textBase = css`
  font-size: 16px;
  line-height: 1.5;
`;

export const textLg = css`
  font-size: 18px;
  line-height: 1.59;
  letter-spacing: -0.02em;
`;

export const textXl = css`
  font-size: 20px;
  line-height: 1.45;
  letter-spacing: -0.03em;
`;

export const text2xl = css`
  font-size: 28px;
  line-height: 1.28;
  letter-spacing: -0.03em;
`;

export const text3xl = css`
  font-size: 32px;
  line-height: 1.28;
  letter-spacing: -0.03em;
`;

export const text4xl = css`
  font-size: 2.25rem;
  line-height: 1.2;
  letter-spacing: -0.03em;
`;

export const text5xl = css`
  font-size: 3rem;
  line-height: 1.2;
  letter-spacing: -0.03em;
`;

export const txtCenter = css`
  text-align: center;
`;

export const txtRight = css`
  text-align: right;
`;
