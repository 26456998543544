import { css } from "@emotion/react";

export * from "../styles/shared";

export { fadeIn } from "../styles/animations";

export const navList = css`
  display: grid;
  grid-row-gap: 0;

  @media (min-width: 800px) {
    grid-template-columns: auto;
    grid-auto-flow: column;
    grid-row-gap: 0;
    grid-column-gap: 30px;
    align-items: center;
  }

  @media (max-width: 799px) {
    --link: var(--gray-dark);
    --link-border: transparent;

    margin-top: var(--nav-height);
    border-top: 1px solid var(--border-color);
  }
`;

export const navLi = css`
  @media (max-width: 799px) {
    border-bottom: 1px solid var(--border-color);
  }
`;

export const borderLiTop = css`
  border-top: 1px solid var(--border-color);
`;

export const borderLiBot = css`
  border-top: 1px solid var(--border-color);
`;

export const navLinkText = css`
  &.active,
  &:hover,
  [aria-current="page"] {
    color: var(--red);
  }

  @media (min-width: 800px) {
    font-size: 0.875rem;  /* 14px */
  }
`;

export const navLink = css`
  ${navLinkText}

  @media (max-width: 799px) {
    display: block;
    padding: 8px 30px;
  }
`;

export const navButton = css`
  ${navLinkText}

  color: var(--red);
  border: 2px solid var(--red);
  padding: 6px 16px;

  &:hover,
  &:active,
  &:focus {
    color: var(--white);
    background-color: var(--red);
  }
`;

export const toggleArrow = css`
  position: absolute;
  top: calc(50% - 7px);
  left: 12px;
  transition: 0.3s ease-in-out;
`;

export const arrowRotate = css`
  transform: rotate(90deg);
`;

export const subPanel = css`
  border-radius: 2px;
  padding: 30px;
  width: 200px;
  gap: 8px;

  &:focus,
  [data-focus] {
    --chakra-shadows-outline: none;
  }

  &:focus-visible {
    --chakra-shadows-outline: 0 0 0 3px rgba(66, 153, 225, 0.6);
  }
`;
