// import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { colors, rem } from "../styles/utils";

import { fadeIn } from "../styles/animations";

export { baseBtn, w100 } from "../styles/shared";

export const achorLinkOffset = css`
  top: calc(var(--nav-height) * -1 - 20px);
`;

export const navSpacer = css`
  height: var(--nav-height);
`;

export const negativeSpace = css`
  margin-bottom: calc(var(--nav-height) * -1);
`;

export const solid = css`
  --link: var(--gray-dark);
  --link-hover: var(--red);
  --link-border: transparent;

  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.border1pxGray};
  color: ${colors.grayDark};

  @media (min-device-pixel-ratio: 2) {
    border-color: ${colors.grayBGLight};
    border-bottom-width: 0.5px;
  }
`;

export const transparent = css`
  --link: white;
  --link-hover: var(--red);
  --link-border: transparent;

  color: white;
  border-color: transparent;
  background-color: transparent;
`;

export const hidden = css`
  opacity: 0;
  pointer-events: none;
`;

export const shown = css`
  ${fadeIn};
  animation-delay: 0.25s;

  pointer-events: auto;
`;

export const navRoot = css`
  --link: var(--gray-dark);
  --link-hover: var(--red);
  --link-border: transparent;
`;

export const navbar = css`
  position: sticky;
  top: 0;
  width: 100%;
  height: var(--nav-height);
  padding: 12px 32px 12px;
  z-index: 500;

  transition: all 0.25s;

  @media (min-width: 800px) {
    justify-content: space-between;
  }
`;

export const navGrid = css`
  display: grid;
  grid-template-columns: 20px 20px 1fr;
  grid-template-areas: "toggleBtn . logo";
  align-items: center;

  @media (min-width: 360px) {
    grid-template-columns: 20px 20px 1fr 40px;
    grid-template-areas: "toggleBtn . logo .";
  }

  @media (min-width: 800px) {
    grid-template-columns: 320px 30px 1fr;
    grid-template-areas: "logo . navigation";
  }
`;

export const navigation = css`
  grid-area: navigation;

  @media (min-width: 800px) {
    justify-self: flex-end;
  }
`;

export const toggleBtn = css`
  grid-area: toggleBtn;
`;

export const logo = css`
  grid-area: logo;
  justify-self: flex-end;
  --link-hover: var(--gray-dark);

  @media (min-width: 360px) {
    justify-self: center;
  }

  @media (min-width: 800px) {
    justify-self: flex-start;
  }
`;

export const colorInherit = css`
  color: inherit;
`;

export const logoImg = css`
  height: auto;
  max-height: ${rem(30)};
  width: auto;
  max-width: 100%;
`;
