import React from "react";

import MarkdownBlock from "../components/markdown-block";

const AlertMessage = ({ content }) => (
  <div className="relative z-[8000] flex min-h-[3.5rem] items-center justify-center bg-gray-bg-light">
    <div
      className="container py-4 text-center text-sm"
      style={{ "--link-color": "var(--red)" }}
    >
      <MarkdownBlock content={content} components={{ p: React.Fragment }} />
    </div>
  </div>
);

export default AlertMessage;

export const alertMessageFields = {
  label: "Alert Message",
  name: "alertMessage",
  widget: "object",
  fields: [
    {
      label: "Enabled",
      name: "enabled",
      widget: "boolean",
      required: false,
    },
    {
      label: "Content",
      name: "content",
      widget: "markdown",
    },
  ],
};
