import _get from "lodash.get";

import useQueryParams from "app/hooks/use-query-params";

import SiteData from "../content/site-globals.json";

const demoLink = _get(SiteData, "demoLink", false);

const useDemoLink = () => {
  const queryParams = useQueryParams();
  return typeof demoLink !== "string" || demoLink.length
    ? `${demoLink}${queryParams}`
    : "";
};

export default useDemoLink;

export const demoButtonFields = {
  label: "Airtable Links",
  name: "airTableLinks",
  widget: "code",
  default_language: "json",
  required: false,
};

export const demoUrlField = {
  label: "Demo Link",
  name: "demoLink",
  widget: "string",
  hint: "If provided, this will replace the airtable campaign for all Request Demo links.",
  required: false,
};
