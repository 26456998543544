import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const block = css`
  display: block;
  text-decoration: none;
  color: var(--link);
  border: 0;

  &:hover,
  &:active,
  &:focus {
    color: var(--link-hover);
    border: 0;
  }
`;

export const text = css`
  text-decoration: none;
  color: var(--link);
  border-bottom: 1px solid var(--link-border);

  &:hover,
  &:active,
  &:focus {
    color: var(--link-hover);
    border-color: transparent;
  }
`;

export const Link = styled.a`
  display: inline-block;
  cursor: pointer;
`;
