import { useState, useEffect } from "react";
import { useLocation } from "@reach/router";

const useQueryParams = () => {
  const [queryParams, setQueryParams] = useState();
  const { search } = useLocation();

  useEffect(() => {
    if (search) {
      setQueryParams(search);
    }
  }, [search, setQueryParams]);

  return !!queryParams ? queryParams : "";
};

export default useQueryParams;
